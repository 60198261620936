import { useState, useEffect } from "react";

import './AccessDash.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { SvgAddNewData, SvgCancel, SvgDelete, SvgEdit, SvgSearch, SvgUser, SvgUserDisabled } from "components/SvgFile";

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

export default function Page_AccessDash(){

    const [ search, setSearch ]             = useState('');
    const [ userId, setUserId ]             = useState(GetUserData('id'));
    const [ showPageData, setShowPageData ] = useState(GetDataPage('access_dash'));

    const [ itensPage, setItensPage ] = useState(30);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showPageData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('access_dash', setShowPageData);
    }, []);

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('access_dash').forEach(item =>{
                if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.email.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(GetDataPage('access_dash'));
        }
        setSearch(value);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'access_dash_details')
        SetListPag('currentPageId', idPage);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function AltAccess(type, id, value){
        SetModalData('Confirmation', { "origin": "access_dash", "type" : type, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "access_dash", "type" : "delete_user", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Page_AccessDash">
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#111827" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="pesquisar..." />
                </div>
                <div className="title_page">Acesso a dashboard</div>
                <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                    <SvgAddNewData color="#ffffff" className="icons" />
                    usuário
                </div>
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Nome</th>
                            <th width="120">E-mail</th>
                            <th width="110">Tipo de acesso</th>
                            <th width="20">Foto</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{
                                let access = '';
                                switch (key.access) {
                                    case 0: access = "Usuário"; break;
                                    case 1: access = "Administrador"; break;
                                }                                                                
                                
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.name }</td>
                                        <td>{ key.email }</td>
                                        <td>{ access }</td>
                                        <td align="center">
                                            <div>
                                                {
                                                    key.file !='' ?
                                                    <>
                                                        <img data-tooltip-id={ "file_" + key.id } data-tooltip-content="Visualizar foto" data-tooltip-place="top" alt="profile user" src={ "./assets/login/" + key.file } className="icon_user" onClick={ ()=>{ OpenFile('showImg', "login/" + key.file) } } />
                                                        <Tooltip id={ "file_" + key.id } />
                                                    </> :
                                                    <SvgUser color="#324d6b" className="icon_user" />
                                                }
                                            </div>
                                        </td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                {
                                                    userId != key.id ? 
                                                    <>
                                                        {
                                                            key.status == 0 ?
                                                            <>
                                                                <div data-tooltip-id={ "status_" + key.id } data-tooltip-content="Liberar acesso do usuário" data-tooltip-place="top" onClick={ ()=>{ AltAccess('reactivate_access_dash', key.id, key.name) } }>
                                                                    <SvgUserDisabled color="#F00000" className="icons"/>
                                                                </div>
                                                                <Tooltip id={ "status_" + key.id } />
                                                            </> :
                                                            <>
                                                                <div data-tooltip-id={ "status_" + key.id } data-tooltip-content="Remover acesso do usuário" data-tooltip-place="top" onClick={ ()=>{ AltAccess('disabled_access_dash', key.id, key.name) } }>
                                                                    <SvgCancel color="#F00000" className="icons"/>
                                                                </div>
                                                                <Tooltip id={ "status_" + key.id } />
                                                            </>
                                                        }           
                                                        <div data-tooltip-id={ "del_" + key.id } data-tooltip-content="Deletar usuário" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, key.name) } } title="Deletar usuário">
                                                            <SvgDelete color="#F00000" className="icons"/>
                                                        </div>
                                                        <Tooltip id={ "del_" + key.id } />
                                                    </> : null
                                                }
                                                <div data-tooltip-id={ "edi_" + key.id } data-tooltip-content="Editar usuário" data-tooltip-place="top" onClick={ ()=>{ PageClick(key.id) } } title="Editar usuário">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                                <Tooltip id={ "edi_" + key.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showPageData.length >= 30 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}