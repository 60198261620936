import { useState, useEffect } from "react";

import './Panel.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { SvgAddNewData, SvgDelete, SvgEdit, SvgSearch, SvgSetaDown, SvgSetaUp } from "components/SvgFile";

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { Reg_AltPosition } from "services/RegisterData";

export default function Page_Panel(){

    const [ loading, setLoading ]           = useState(false);
    const [ search, setSearch ]             = useState('');
    const [ userId, setUserId ]             = useState(GetUserData('id'));
    const [ showPageData, setShowPageData ] = useState(GetDataPage('panels'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showPageData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('panels', setShowPageData);
    }, []);

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('panels').forEach(item =>{
                if(item.idData.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.sigla.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.branch.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.city.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.state.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.address.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(GetDataPage('panels'));
        }
        setSearch(value);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'panels_details')
        SetListPag('currentPageId', idPage);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "panels", "type" : "delete_panel", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function ChangePosition(id, order, type){
        setLoading(true);
        Reg_AltPosition('panels', id, order, type, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Page_Panel">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <div className="newtons-cradle">
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                </div>
            </div>
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#111827" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="pesquisar..." />
                </div>
                <div className="title_page">Painel</div>
                <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                    <SvgAddNewData color="#ffffff" className="icons" />
                    painel
                </div>
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th width="40">ID</th>
                            <th>Cidade</th>
                            <th>Endereço</th>
                            <th width="60">Imagem</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{                                                        
                                
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.idData }</td>
                                        <td>{ key.city }</td>
                                        <td>{ key.address }</td>
                                        <td align="center">
                                            <div>
                                                {
                                                    key.file !='' ?
                                                        key.id > 408 ?
                                                            <>
                                                                <img data-tooltip-id={ "file_" + key.id } data-tooltip-content="Visualizar foto" data-tooltip-place="top" alt="profile user" src={ "./assets/panel/" + key.file } className="icon_file" onClick={ ()=>{ OpenFile('showImg', "panel/" + key.file) } } />
                                                                <Tooltip id={ "file_" + key.id } />
                                                            </> :
                                                            <>
                                                                <img data-tooltip-id={ "file_" + key.id } data-tooltip-content="Visualizar foto" data-tooltip-place="top" alt="profile user" src={ key.file } className="icon_file" onClick={ ()=>{ OpenFile('showImg', "link_" + key.file) } } />
                                                                <Tooltip id={ "file_" + key.id } />
                                                            </> : null
                                                }
                                            </div>
                                        </td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div data-tooltip-id={ "del_" + key.id } data-tooltip-content="Deletar painel" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, key.name) } } title="Deletar painel">
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <Tooltip id={ "del_" + key.id } />
                                                <div data-tooltip-id={ "edi_" + key.id } data-tooltip-content="Editar painel" data-tooltip-place="top" onClick={ ()=>{ PageClick(key.id) } } title="Editar painel">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                                <Tooltip id={ "edi_" + key.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showPageData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}