import { useState, useEffect } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { SetModalData, SetModalState } from 'interface/PopUp';
import { GetUserData, RegisterUserData } from 'interface/Users';
import { GetListPag, SetListPag, RegisterListPag } from 'interface/Page';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePassw, typeCookiePage } from 'dataFixed';

import { SvgDashboard, SvgDashboardExit, SvgDashboardPanels, SvgMenuCourse, SvgMenuFinance, SvgMenuGraphic, SvgMenuHamburguer, SvgMenuPoint, SvgMenuSite, SvgMenuStudents, SvgMenuTeam, SvgSetaRight, SvgSite, SvgUser } from 'components/SvgFile';


import PopUp_ShowFile from 'components/PopUp/ShowFile';
import PopUp_PhotoCrop from 'components/PopUp/PhotoCrop';
import PopUP_EditProfile from 'components/PopUp/EditProfile';
import PopUP_Confirmation from 'components/PopUp/Confirmation';
import PopUP_ReturnResponse from 'components/PopUp/ReturnResponse';

import Page_AccessDash from 'components/Page/AccessDash';
import Page_AccessDash_Details from 'components/Page/AccessDash/Details';

import Page_Finacial_Entry from 'components/Page/Financial/Entry';
import Page_Finacial_Entry_details from 'components/Page/Financial/Entry/Details';

import Page_Finacial_Exit from 'components/Page/Financial/Exit';
import Page_Finacial_Exit_details from 'components/Page/Financial/Exit/Details';

import Page_Finacial_Balance from 'components/Page/Financial/Balance';

import Page_Site from 'components/Page/Site';
import Page_Panel from 'components/Page/Panel';
import Page_NameSite from 'components/Page/NameSite';
import Page_Products from 'components/Page/Products';
import Page_Site_Details from 'components/Page/Site/Details';
import Page_Panel_Details from 'components/Page/Panel/Details';
import Page_Products_Details from 'components/Page/Products/Details';

export default function Contents(){

    const cookies = new Cookies();
    const [ status, setStatus ]   = useState(false);

    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ]     = useState(GetUserData('page'));

    const [ menuFinancial, setMenuFinancial ]        = useState(false);
    const [ listMenuFinancial, setListSubFinancial ] = useState([
        "entry", "entry_details", 
        "exit", "exit_details", 
        "balance"
    ]);

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);
        
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);
    }, []);

    function CkickPage(value){
        setTimeout(() => {
            SetListPag('currentPage', value);
        }, 100);
    }

    function EditProfile(){
        SetModalState('EditProfile', true);
    }
    
    function CurrentPage(){
        switch(currentPage) {
            case "access_dash":
                return <Page_AccessDash />;
            case "access_dash_details":
                return <Page_AccessDash_Details />;

            case "site":
                return <Page_Site />;
            case "site_details":
                return <Page_Site_Details />;

            case "products":
                return <Page_Products />;
            case "products_details":
                return <Page_Products_Details />;

            case "panels":
                return <Page_Panel />;
            case "panels_details":
                return <Page_Panel_Details />;

            case "name_menu":
                return <Page_NameSite />;

            case "entry":
                return <Page_Finacial_Entry />;
            case "entry_details":
                return <Page_Finacial_Entry_details />;

            case "exit":
                return <Page_Finacial_Exit />;
            case "exit_details":
                return <Page_Finacial_Exit_details />;

            case "balance":
                return <Page_Finacial_Balance />;

            default:
                return(
                    <div className="no_data_content">
                        Selecione uma opção do menu na lateral para continuar...
                    </div>
                )
        }
    }

    function UpdateJson(){
        SetModalData('Confirmation', { "origin": "update_json", "type": "update_json" });
        SetModalState('Confirmation', true);
    }

    function Logout(){
        SetListPag('page', 'index');
        cookies.set(typeCookie, 'index', '/', cookiesRegister);

        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    return(
        <div className="Contents">
            <a href="https://amplamidiaexterior.com.br/" target="_blank">
                <div className="show_site">
                    <SvgSite color="#ffffff" className="icons_site" />
                </div>
            </a>
            <div className={ status == true ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">
                    <div className="show_data_top">

                        <div className="div_logo" onClick={ ()=>{ setStatus(!status); setMenuFinancial(false); } }>
                            <div className={ status == true ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                                <div className="">
                                    <img alt="logotipo" src="./assets/logotipo.png" className="logotipo" />
                                </div>
                                {
                                    status == true ? null :
                                    <div className="">
                                        <SvgMenuHamburguer color="#ffffff" className="icons" />
                                    </div>
                                }
                            </div>
                        </div>
                        
                        <div className="opt_menu_dash">
                            {
                                userAccess == 1 ?
                                <>
                                    <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); setMenuFinancial(false); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboard color="#FFFFFF" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Acessos a Dashboard</div>
                                        </div>
                                    </div>

                                    
                                    <div className={ currentPage == "site" || currentPage == "site_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("site"); setMenuFinancial(false); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenuSite color="#FFFFFF" className="icons" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Site</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "products" || currentPage == "products_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("products"); setMenuFinancial(false); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenuSite color="#FFFFFF" className="icons" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Produtos</div>
                                        </div>
                                    </div> 

                                    <div className={ currentPage == "name_menu" || currentPage == "name_menu_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("name_menu"); setMenuFinancial(false); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenuHamburguer color="#FFFFFF" className="icons" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Menu do site</div>
                                        </div>
                                    </div>
                                   

                                    <div className={ currentPage == "panels" || currentPage == "panels_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("panels"); setMenuFinancial(false); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardPanels color="#FFFFFF" className="icons" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Painel</div>
                                        </div>
                                    </div>

                                    <div className={ status == true ? listMenuFinancial.find(item => item == currentPage) ? "show_menu_dash active_dash" : "show_menu_dash show_menu_dash_" : "show_menu_dash" }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" } onClick={ ()=>{ setMenuFinancial(!menuFinancial); setStatus(false); } }>
                                            <div className="div_icons">
                                                <SvgMenuFinance color="#FFFFFF" className="icons" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Financeiro</div>
                                            <div className={ status == true ? "menu_close" : "icons_submenu" }>
                                                {
                                                    menuFinancial == true || listMenuFinancial.find(item => item == currentPage) ?
                                                    <SvgSetaRight color="#ffffff" className="icons icons_active" /> :
                                                    <SvgSetaRight color="#ffffff" className="icons" />
                                                }
                                            </div>
                                        </div>
                                        {
                                            status == true ? null :                                          
                                            <div className={ menuFinancial == true || listMenuFinancial.find(item => item == currentPage) ? "name_submenu submenu_active" : "name_submenu" }>
                                                <div className={ currentPage == "entry" || currentPage == "entry_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("entry") } }>
                                                    <div>Entrada</div>
                                                </div>
                                                <div className={ currentPage == "exit" || currentPage == "exit_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("exit") } }>
                                                    <div>Saída</div>
                                                </div>
                                                <div className={ currentPage == "balance" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("balance") } }>
                                                    <div>Balanço</div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="show_menu_dash" onClick={ ()=>{ UpdateJson() } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardPanels color="#FFFFFF" className="icons" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Atualizar Site</div>
                                        </div>
                                    </div>
                                </> 
                                :
                                <>
                                    {
                                        userPage.map((key, index)=>{
                                            let iconMenu   = '';
                                            let activeMenu = 'show_menu_dash';
                                            switch (key.page) {
                                                case 'financial':
                                                        iconMenu = <SvgMenuFinance color="#FFFFFF" className="icons" />;
                                                    break;
                                            }

                                            if(currentPage == key.page){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";
                                            }

                                            switch (key.page) {
                                                case 'financial':
                                                    if(key.submenu.length > 0){
                                                        return(
                                                            <div className={ status == true ? listMenuFinancial.find(item => item == currentPage) ? "show_menu_dash active_dash" : "show_menu_dash show_menu_dash_" : "show_menu_dash" } key={ index }>
                                                                <div className={ status == true ? "name_menu name_menu_" : "name_menu" } onClick={ ()=>{ setMenuFinancial(!menuFinancial); setStatus(false); } }>
                                                                    <div className="div_icons">
                                                                        <SvgMenuFinance color="#FFFFFF" className="icons" />
                                                                    </div>
                                                                    <div className={ status == true ? "menu_close" : "" }>Financeiro</div>
                                                                    <div className={ status == true ? "menu_close" : "icons_submenu" }>
                                                                        {
                                                                            menuFinancial == true || listMenuFinancial.find(item => item == currentPage) ?
                                                                            <SvgSetaRight color="#ffffff" className="icons icons_active" /> :
                                                                            <SvgSetaRight color="#ffffff" className="icons" />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    status == true ? null :
                                                                    <div className={ menuFinancial == true || listMenuFinancial.find(item => item == currentPage) ? "name_submenu submenu_active" : "name_submenu" }>
                                                                        {                     
                                                                            key.submenu.map((key_1, index_1)=>{
                                                                                return(
                                                                                    <div className={ currentPage == key_1.name || currentPage == key_1.name + "_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage(key_1.name) } } key={ index_1 }>
                                                                                        <div>{ key_1.page }</div>
                                                                                    </div>
                                                                                )
                                                                            })  
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    }

                                                default:
                                                    return(
                                                        <div className={ activeMenu } onClick={ ()=>{ CkickPage(key.page); setMenuFinancial(false); } } key={ index }>
                                                            <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                                <div className="div_icons">
                                                                    { iconMenu }
                                                                </div>
                                                                <div className={ status == true ? "menu_close" : "" }>
                                                                    { key.name }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )

                                            }
                                            
                                        })
                                    }
                                </>
                            }

                            <div className={ status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboardExit color="#FFFFFF" className="icons_dash" />
                                    </div>
                                    <div className={ status == true ? "menu_close" : "" }>Sair</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="div_profile">
                        <div className={ status == true ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ EditProfile() } }>
                            <div className="div_img">
                                {
                                    userFile !='' ?
                                    <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                    <SvgUser color="#FFFFFF" className="show_img_profile" />
                                }
                            </div>
                            <div className={ status == true ? "name_user menu_close" : "name_user" }>
                                { userName }
                            </div>
                            <div className={ status == true ? "open_profile menu_close" : "open_profile" }>
                                <SvgMenuPoint color="#ffffff" className="icons" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
            <PopUP_Confirmation />
            <PopUP_ReturnResponse />
            <PopUp_ShowFile />
            <PopUp_PhotoCrop />
            <PopUP_EditProfile />
        </div>
    )
}
