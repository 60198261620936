import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config, listState } from "dataFixed";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgDelete, SvgImg, SvgLink, SvgReturn, SvgSave, SvgYoutube } from "components/SvgFile";

import { Reg_Panel } from "services/RegisterData";

export default function Page_Panel_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    
    const [ dataPage, setDataPage ]       = useState(GetDataPage('panels'));
    const [ optionsData, setOptionsData ] = useState(GetDataPage('panels_data'));

    const [ idData, setIdData ]                 = useState(InitialData('idData'));
    const [ premium, setPremium ]               = useState(InitialData('premium'));
    const [ file, setFile ]                     = useState(InitialData('file'));
    const [ status, setStatus ]                 = useState(false);
    const [ dateStart, setDateStart ]           = useState(InitialData('date_start'));
    const [ dateEnd, setDateEnd ]               = useState(InitialData('date_end'));
    const [ client, setClient ]                 = useState(InitialData('client'));
    const [ clientName, setClientName ]         = useState(InitialData('client_name'));
    const [ newClient, setNewClient ]           = useState('');
    const [ type, setType ]                     = useState(InitialData('type'));
    const [ typeName, setTypeName ]             = useState(InitialData('type_name'));
    const [ newType, setNewType ]               = useState('');
    const [ format, setFormat ]                 = useState(InitialData('format'));
    const [ formatName, setFormatName ]         = useState(InitialData('format_name'));
    const [ newFormat, setNewFormat ]           = useState('');
    const [ state, setState ]                   = useState(InitialData('state'));
    const [ city, setCity ]                     = useState(InitialData('city'));
    const [ neighborhood, setNeighborhood ]     = useState(InitialData('neighborhood'));
    const [ newHood, setNewHood ]               = useState('');
    const [ address, setAddress ]               = useState(InitialData('address'));
    const [ obsAddress, setObsAddress ]         = useState(InitialData('obs_address'));
    const [ latitude, setLatitude ]             = useState(InitialData('latitude'));
    const [ longitude, setLongitude ]           = useState(InitialData('longitude'));
    const [ flowGenerators, setFlowGenerators ] = useState(InitialData('flow_generators'));
    const [ size, setSize ]                     = useState(InitialData('size'));
    const [ streetView, setStreetView ]         = useState(InitialData('street_view'));
    const [ codeVideo, setCodeVideo ]           = useState(InitialData('code_video'));
    const [ text, setText ]                     = useState(InitialData('text'));

    const [ coverage, setCoverage ] = useState(InitialData('coverage'));
    const [ newCvrg, setNewCvrg ]   = useState([]);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterListPag('currentPageId', setIdPage);
        
        RegisterDataPage('panels', setDataPage);
        RegisterDataPage('panels_data', setOptionsData);
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        
        setDataPage(GetDataPage('panels'));
        setOptionsData(GetDataPage('panels_data'));

        setIdData(InitialData('idData'));
        setPremium(InitialData('premium'));
        setFile(InitialData('file'));
        setStatus(false);
        setDateStart(InitialData('date_start'));
        setDateEnd(InitialData('date_end'));
        setClient(InitialData('client'));
        setClientName(InitialData('client_name'));
        setNewClient('');
        setType(InitialData('type'));
        setTypeName(InitialData('type_name'));
        setNewType('');
        setFormat(InitialData('format'));
        setFormatName(InitialData('format_name'));
        setNewFormat('');
        setState(InitialData('state'));
        setCity(InitialData('city'));
        setNeighborhood(InitialData('neighborhood'));
        setNewHood('');
        setAddress(InitialData('address'));
        setObsAddress(InitialData('obs_address'));
        setLatitude(InitialData('latitude'));
        setLongitude(InitialData('longitude'));
        setFlowGenerators(InitialData('flow_generators'));
        setSize(InitialData('size'));
        setStreetView(InitialData('street_view'));
        setCodeVideo(InitialData('code_video'));
        setText(InitialData('text'));

        setCoverage(InitialData('coverage'));
        setNewCvrg([]);
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            console.log(newData);
            if(type == 'state'){
                return newData['sigla'] + ' - ' + newData['state'];
            }
            if(type == 'client_name' || type == 'type_name' || type == 'format_name' || type == 'neighborhood'){
                if(newData[type]){
                    return newData[type];
                }else {
                    return 'Selecione uma opção';
                }
            }
            return newData[type];
        }
        if(type == 'premium'){
            return 'Não';
        }
        if(type == 'client' || type == 'type' || type == 'format'){
            return "";
        }
        if(type == 'client_name' || type == 'type_name' || type == 'format_name' || type == 'branch' || type == 'state' || type == 'city' || type == 'neighborhood'){
            return 'Selecione uma opção';
        }
        if(type == 'coverage'){
            return [];
        }
        return '';
    }

    function ListSelect(value){
        const options = [{ "value": "Adicionar novo", "label": "Adicionar novo"}]
        optionsData.map((key, index)=>{
            if(key.type == value){
                options.push({
                    "value": key.id, "label": key.name
                });
            }
        });
        return options;
    }

    function ListCoverage(){
        const options = [];
        const newData = [];
        dataPage.map((key, index)=>{
            if(key.neighborhood !=''){
                newData.push(key.neighborhood);
            }
        });

        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        duplicate.map((key, index)=>{
            options.push({
                "value": key, "label": key
            });
        });
        return options;
    }

    function ShowState(){
        const options = []
        listState.map((key, index)=>{
            let nameState = key.sigla + ' - ' + key.nome;
            options.push({
                "value": nameState, "label": nameState
            });
        });
        return options;
    }

    function ShowCity(){
        const options = []
        if(state !='Selecione uma opção'){
            let sep = state.split('-');
            if(sep[1].replace(' ', '') !=''){
                const newData = listState.find(item => item.nome == sep[1].replace(' ', ''));
                if(newData){
                    newData['cidades'].map((key, index)=>{
                        options.push({
                            "value": key.replace('&#39;', "'"), "label": key.replace('&#39;', "'")
                        });
                    })
                }
            }
        }
        return options;
    }

    function ShowNeighborhood(){
        const options = [{ "value": "Adicionar novo bairro", "label": "Adicionar novo bairro" }];
        const newData = [];
        dataPage.map((key, index)=>{
            if(key.neighborhood !=''){
                newData.push(key.neighborhood);
            }
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        duplicate.map((key, index)=>{
            options.push({
                "value": key, "label": key
            });
        })
        return options;
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function Delete(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "panels", "type": "delete_coverage", "name": value });
        SetModalState('Confirmation', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'panels');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Panel(userId, idPage, idData, premium, file, dateStart, dateEnd, client, newClient, type, newType, format, newFormat, state, city, neighborhood, newHood, address, obsAddress, latitude, longitude, flowGenerators, size, streetView, codeVideo, text, newCvrg, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Page_Panel_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <div className="newtons-cradle">
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                </div>
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text" onClick={ ()=>{  } }>
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do painel</div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="data_code" onChange={ (e)=>{ setIdData(e.target.value) } } value={ idData } maxLength="10" required />
                            <span className="name_input">ID</span>
                        </div> 
                        <div className="div_input">
                            <Select className="select_premium" defaultOptions options={[ { "value": "Não", "label": "Não" }, { "value": "Sim", "label": "Sim" } ]} value={ { "value": premium, "label": premium } } onChange={ (e)=> { setPremium(e.value); } } placeholder="..." />
                            <span className="name_input">Premium</span>
                        </div>             
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]) } } accept="image/*" />
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>                                        
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('') } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        status == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', idPage > 408 ? "panel/" + file : "link_" + file) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>

                    <div className="list_input_data">  
                        <div className="div_input">
                            <input type="date" className="input_date" onChange={ (e)=>{ setDateStart(e.target.value) } } value={ dateStart } />
                            <span className="name_input">Início da ocupação</span>
                        </div>           
                        <div className="div_input">
                            <input type="date" className="input_date" onChange={ (e)=>{ setDateEnd(e.target.value) } } value={ dateEnd } />
                            <span className="name_input">Término da ocupação</span>
                        </div>   
                        <div className="div_input space_div">
                            <Select className="space_div" defaultOptions options={ ListSelect('client') } value={ { "value": client, "label": clientName } } onChange={ (e)=> { setClient(e.value); setClientName(e.label) } } placeholder="..." />
                            <span className="name_input">Ocupado para o cliente</span>
                        </div> 
                        {
                            client != 'Adicionar novo' ? null :
                            <div className="div_input space_div">
                                <input type="text" className="" onChange={ (e)=>{ setNewClient(e.target.value) } } value={ newClient } maxLength="40" required />
                                <span className="name_input">Nome do novo cliente</span>
                            </div> 
                        }
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <Select className="space_div" defaultOptions options={ ListSelect('type') } value={ { "value": type, "label": typeName } } onChange={ (e)=> { setType(e.value); setTypeName(e.label); } } placeholder="..." required />
                            <span className="name_input">Tipo</span>
                        </div>  
                        {
                            type != 'Adicionar novo' ? null :
                            <div className="div_input space_div">
                                <input type="text" className="" onChange={ (e)=>{ setNewType(e.target.value) } } value={ newType } maxLength="40" required />
                                <span className="name_input">Nome do novo tipo</span>
                            </div> 
                        }   
                        <div className="div_input space_div">
                            <Select className="space_div" defaultOptions options={ ListSelect('format') } value={ { "value": format, "label": formatName } } onChange={ (e)=> { setFormat(e.value); setFormatName(e.label); } } placeholder="..." />
                            <span className="name_input">Formato</span>
                        </div> 
                        {
                            format != 'Adicionar novo' ? null :
                            <div className="div_input space_div">
                                <input type="text" className="" onChange={ (e)=>{ setNewFormat(e.target.value) } } value={ newFormat } maxLength="40" required />
                                <span className="name_input">Nome do novo formato</span>
                            </div> 
                        }
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <Select className="space_div" defaultOptions options={ ShowState() } value={ { "value": state, "label": state } } onChange={ (e)=> { setState(e.value); setCity(''); setNeighborhood(''); } } placeholder="..." required />
                            <span className="name_input">Estado</span>
                        </div>
                        <div className="div_input space_div">
                            <Select className="space_div" defaultOptions options={ ShowCity() } value={ { "value": city, "label": city } } onChange={ (e)=> { setCity(e.value); setNeighborhood(''); } } placeholder="..." required />
                            <span className="name_input">Cidade</span>
                        </div>
                        <div className="div_input">
                            <Select className="neighborhood" defaultOptions options={ ShowNeighborhood() } value={ { "value": neighborhood, "label": neighborhood } } onChange={ (e)=> { setNeighborhood(e.value); } } placeholder="..." required />
                            <span className="name_input">Bairro</span>
                        </div>
                    </div>

                    {
                        neighborhood == 'Adicionar novo bairro' ?
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" className="" onChange={ (e)=>{ setNewHood(e.target.value) } } value={ newHood } required />
                                <span className="name_input">Nome do bairro</span>
                            </div>
                        </div>
                        : null
                    }

                    <div className="list_input_data">
                        <div className="div_input space_div show_data_text">
                            <span className="show_title">Cobertura</span>
                            <div className="div_input space_div">
                                <Select className="space_data" defaultOptions options={ ListCoverage() } onChange={ (e)=> { setNewCvrg(e); } } value={ newCvrg } placeholder="..." isMulti isOptionDisabled={ (opt)=> coverage.length >= 3} />
                            </div>
                            {
                                coverage.length > 0 ?
                                <div className="div_coverage">
                                    {                                        
                                        coverage.map((key, index)=>{
                                            return(
                                                <div className="show_coverage" key={ index }>
                                                    <div className="">
                                                        Bairro: { key.name }
                                                    </div>
                                                    <div className="" onClick={ ()=>{ Delete(key.id, key.name) } } style={ { display: 'flex' } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div> : ""
                            }
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setAddress(e.target.value) } } value={ address } required />
                            <span className="name_input">Endereço</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Observação do endereço</div>
                            <JoditEditor className="show_textarea" config={ config } value={ obsAddress ? obsAddress.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setObsAddress(newContent) } />
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setLatitude(e.target.value) } } value={ latitude } required />
                            <span className="name_input">Latitude</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setLongitude(e.target.value) } } value={ longitude } required />
                            <span className="name_input">Longitude</span>
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setFlowGenerators(e.target.value) } } value={ flowGenerators } />
                            <span className="name_input">Geradores de fluxo</span>
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="" onChange={ (e)=>{ setSize(e.target.value) } } value={ size } />
                            <span className="name_input">Tamanho</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setStreetView(e.target.value) } } value={ streetView } />
                            <span className="name_input">Vista da rua</span>
                        </div>
                        {
                            streetView == "" ? null :
                            <div className="div_input">
                                <a href={ streetView } target="_blank">
                                    <SvgLink className="icons" color="#061821" />
                                </a>
                            </div>
                        }
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setCodeVideo(e.target.value) } } value={ codeVideo } />
                            <span className="name_input">Link do Youtube</span>
                        </div>
                        {
                            codeVideo == '' ? null :
                            <div className="div_input" onClick={ ()=>{ OpenFile('Youtube', codeVideo) } }>
                                <SvgYoutube className="icons" color="#061821" />
                            </div>
                        }
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">
                                <div className="">Observação interna</div>
                                <div className="subtitle">Não visível no site</div>
                            </div>
                            <JoditEditor className="show_textarea" config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}