import { useState, useEffect } from "react";

import './Login.css';

import Access from "services/AccessDash";

import { SvgLogotipo } from "components/SvgFile";

export default function Login(){

    const [ email, setEmail ]       = useState('');
    const [ password, setPassword ] = useState('');
    const [ msgError, setMsgError ] = useState('');

    function Register(event){
        event.preventDefault();
        Access("logar", email, password, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() })
    }

    function CallbackSuccess(){
    }

    function CallbackError(){
        setMsgError("E-mail ou senha incorreta!");
    }

    return(
        <div className="Login">
            <form onSubmit={ Register }>
                <div className="content">
                    <div className="div_logo">
                        <img alt="logotipo" src="./assets/logotipo.png" color="#324D6B" className="logo" />
                    </div>
                    <div className="div_input">
                        <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } required placeholder="exemplo@site.com.br" />
                        <span className="name_input">E-mail*</span>
                    </div>
                    <div className="div_input div_input_login">
                        <input type="password" onChange={ (e)=>{ setPassword(e.target.value) } } value={ password.replaceAll(' ', '') } required placeholder="******" />
                        <span className="name_input">Senha*</span>
                    </div>
                    <div className={ msgError ? "msg_error" : "" }>
                        { msgError }
                    </div>
                    <div className="div_logar">
                        <button type="submit" className="button">
                            Entrar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
