import Axios from 'axios';

import Cookies from "universal-cookie";

import { cookiesRegister, typeCookieEmail, typeCookiePassw } from 'dataFixed';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

// Delete data
export function Reg_DeleteData(currentPage, type, id, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('id', id);
    data.append('edit_', type);
    data.append('current_page', currentPage);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle(currentPage, response.data[currentPage]);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Update json
export function Reg_UpdateJson(CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('current_page', 'update_json');

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/updateJson.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        CallbackSuccess();
    }).catch((error)=>{
        CallbackError();
    })
}

// Edit data user
export function Reg_UserDash(userId, idPage, name, pass, email, file, access, addPage, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();
    data.append('edit_', 'edit_profile');
    data.append('current_page', 'access_dash');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('pass', pass);
    data.append('email', email);
    data.append('file', file);
    data.append('access', access);
    addPage.forEach((element, index) => {
        data.append('page_value[]', element.value);
        data.append('page_name[]', element.label);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('access_dash', response.data.access_dash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        if(userId == idPage){
            const newData = response.data.access_dash.find(item => item.id == userId);
            cookies.set(typeCookieEmail, email, '/', cookiesRegister);
            if(pass !=''){
                cookies.set(typeCookiePassw, newData['pass'], '/', cookiesRegister);
            }
            SetUserData('id', newData['id']);
            SetUserData('file', newData['file']);
            SetUserData('access', newData['access']);
            SetUserData('page', newData['page']);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit edit user
export function Reg_EditUser(id, name, email, pass, file, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();
    data.append('type_post', 'edit_user');

    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);

        cookies.set(typeCookieEmail, email, '/', cookiesRegister);
        if(pass !=''){
            cookies.set(typeCookiePassw, response.data['password'], '/', cookiesRegister);
        }
        
        SetUserData('file', response.data.file);
        SetUserData('name', response.data.name);
        SetUserData('email', response.data.email);    
        SetListDataSingle('access_dash', response.data.access_dash);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Register financial
export function Reg_Financial(type, idPage, idUser, title, status, typePayment, newTypePayment, bank, newBank, agency, bill, dueDate, paymentData, cash, fee, file, origin, newOrigin, text, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_', 'edit_or_register');
    data.append('current_page', 'financial');

    data.append('type', type);

    data.append('idPage', idPage);
    data.append('idUser', idUser);
    data.append('title', title);
    data.append('status', status);
    data.append('type_payment', typePayment);
    data.append('newTypePayment', newTypePayment);
    data.append('bank', bank);
    data.append('newBank', newBank);
    data.append('agency', agency);
    data.append('bill', bill);
    data.append('due_date', dueDate);
    data.append('payment_data', paymentData);
    data.append('cash', cash);
    data.append('fee', fee);
    data.append('file', file);
    data.append('origin', origin);
    data.append('newOrigin', newOrigin);
    data.append('text', text);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle(type, response.data[type]);
        SetListDataSingle('balance', response.data.balance);

        SetListDataSingle('list_payment', response.data.list_payment);
        SetListDataSingle('list_bank', response.data.list_bank);
        SetListDataSingle('list_origin', response.data.list_origin);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Change position
export function Reg_AltPosition(current_page, id, order, type, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'alt_position');
    data.append('current_page', current_page);

    data.append('id', id);
    data.append('order', order);
    data.append('type', type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle(current_page, response.data[current_page]);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Alt status menu
export function Reg_StatusMenu(id, status, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'alt_status');
    data.append('current_page', 'name_menu');

    data.append('id', id);
    data.append('status', status);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('name_menu', response.data.name_menu);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Edit name menu
export function Reg_EditMenu(userId, dataPage, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_name_menu');
    data.append('current_page', 'name_menu');

    dataPage.forEach((element, index) => {
        data.append('id[]', element.id);
        data.append('order_[]', element.order_);
        data.append('name[]', element.name);
        data.append('status[]', element.status);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('name_menu', response.data.name_menu);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    });
}

// Edit Products
export function Reg_Products(id, title, text_1, text_2, file, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_or_register');
    data.append('current_page', 'products');

    data.append('id', id);
    data.append('title', title);
    data.append('text_1', text_1);
    data.append('text_2', text_2);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
           
        SetListDataSingle('products', response.data.products);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit Panel
export function Reg_Panel(userId, idPage, idData, premium, file, dateStart, dateEnd, client, newClient, type, newType, format, newFormat, state, city, neighborhood, newHood, address, obsAddress, latitude, longitude, flowGenerators, size, streetView, codeVideo, text, newCvrg, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_or_register');
    data.append('current_page', 'panels');

    data.append('userId', userId);
    data.append('id_page', idPage);
    data.append('idData', idData);
    data.append('premium', premium);
    data.append('file', file);
    data.append('date_start', dateStart);
    data.append('date_end', dateEnd);
    data.append('client', client);
    data.append('new_client', newClient);
    data.append('type', type);
    data.append('new_type', newType);
    data.append('format', format);
    data.append('new_format', newFormat);
    data.append('state', state);
    data.append('city', city);
    data.append('neighborhood', neighborhood);
    data.append('new_hood', newHood);
    data.append('address', address);
    data.append('obs_address', obsAddress);
    data.append('latitude', latitude);
    data.append('longitude', longitude);
    data.append('flow_generators', flowGenerators);
    data.append('size', size);
    data.append('street_view', streetView);
    data.append('code_video', codeVideo);
    data.append('text', text);

    if(newCvrg){
        newCvrg.forEach(element => {
            data.append('newCvrg[]', element.value);
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
           
        SetListDataSingle('panels', response.data.panels);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Edit Site
export function Reg_Site(idPage, title_1, title_2, file, subtitle_1, text_1, subtitle_2, text_2, subtitle_3, text_3, btn_1, name_1, btn_2, name_2, btn_3, name_3, btn_4, name_4, btn_5, name_5, btn_6, name_6, listContact, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_or_register');
    data.append('current_page', 'site');

    data.append('id_page', idPage);
    data.append('title_1', title_1);
    data.append('title_2', title_2);
    data.append('file', file);
    data.append('subtitle_1', subtitle_1);
    data.append('text_1', text_1);
    data.append('subtitle_2', subtitle_2);
    data.append('text_2', text_2);
    data.append('subtitle_3', subtitle_3);
    data.append('text_3', text_3);
    data.append('btn_1', btn_1);
    data.append('name_1', name_1);
    data.append('btn_2', btn_2);
    data.append('name_2', name_2);
    data.append('btn_3', btn_3);
    data.append('name_3', name_3);
    data.append('btn_4', btn_4);
    data.append('name_4', name_4);
    data.append('btn_5', btn_5);
    data.append('name_5', name_5);
    data.append('btn_6', btn_6);
    data.append('name_6', name_6);

    if(listContact){
        listContact.forEach(element => {
            data.append('contact_id[]', element.id);
            data.append('contact_title[]', element.title);
            data.append('contact_phone[]', element.phone);
            data.append('contact_whatsapp[]', element.whatsapp);
            data.append('contact_email[]', element.email);
            data.append('contact_address[]', element.address);
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
           
        SetListDataSingle('site', response.data.site);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}