import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgDelete, SvgImg, SvgReturn, SvgSave } from "components/SvgFile";

import { Reg_Site } from "services/RegisterData";

export default function Page_Site_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('site'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ page, setPage ]         = useState(InitialData('page'));
    const [ namePage, setNamePage ] = useState(InitialData('name'));
    const [ title_1, setTitle_1 ]   = useState(InitialData('title_1'));
    const [ title_2, setTitle_2 ]   = useState(InitialData('title_2'));
    const [ file, setFile ]         = useState(InitialData('file'));
    const [ status, setStatus ]     = useState(false);

    const [ subtitle_1, setSubtitle_1 ] = useState(InitialData('subtitle_1'));
    const [ text_1, setText_1 ]         = useState(InitialData('text_1'));

    const [ subtitle_2, setSubtitle_2 ] = useState(InitialData('subtitle_2'));
    const [ text_2, setText_2 ]         = useState(InitialData('text_2'));

    const [ subtitle_3, setSubtitle_3 ] = useState(InitialData('subtitle_3'));
    const [ text_3, setText_3 ]         = useState(InitialData('text_3'));

    const [ btn_1, setBtn_1 ]   = useState(InitialData('btn_1'));
    const [ name_1, setName_1 ] = useState(InitialData('name_1'));

    const [ btn_2, setBtn_2 ]   = useState(InitialData('btn_2'));
    const [ name_2, setName_2 ] = useState(InitialData('name_2'));

    const [ btn_3, setBtn_3 ]   = useState(InitialData('btn_3'));
    const [ name_3, setName_3 ] = useState(InitialData('name_3'));
    
    const [ btn_4, setBtn_4 ]   = useState(InitialData('btn_4'));
    const [ name_4, setName_4 ] = useState(InitialData('name_4'));
    
    const [ btn_5, setBtn_5 ]   = useState(InitialData('btn_5'));
    const [ name_5, setName_5 ] = useState(InitialData('name_5'));
    
    const [ btn_6, setBtn_6 ]   = useState(InitialData('btn_6'));
    const [ name_6, setName_6 ] = useState(InitialData('name_6'));

    const [ listContact, setListContact ] = useState(InitialData('list_contact'));

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('site', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('site'));
        setIdPage(GetListPag('currentPageId'));

        setPage(InitialData('page'));
        setNamePage(InitialData('name'));
        setTitle_1(InitialData('title_1'));
        setTitle_2(InitialData('title_2'));
        setFile(InitialData('file'));
        setStatus(false);

        setSubtitle_1(InitialData('subtitle_1'));
        setText_1(InitialData('text_1'));
        
        setSubtitle_2(InitialData('subtitle_2'));
        setText_2(InitialData('text_2'));
        
        setSubtitle_3(InitialData('subtitle_3'));
        setText_3(InitialData('text_3'));
        
        setBtn_1(InitialData('btn_1'));
        setName_1(InitialData('name_1'));
        
        setBtn_2(InitialData('btn_2'));
        setName_2(InitialData('name_2'));
        
        setBtn_3(InitialData('btn_3'));
        setName_3(InitialData('name_3'));
        
        setBtn_4(InitialData('btn_4'));
        setName_4(InitialData('name_4'));
        
        setBtn_5(InitialData('btn_5'));
        setName_5(InitialData('name_5'));
        
        setBtn_6(InitialData('btn_6'));
        setName_6(InitialData('name_6'));
        
        setListContact(InitialData('list_contact'));
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];            
        }
        return '';
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    function HandleData(type, index, value){
        const newData = [...listContact];
        newData[index][type] = value;
        setListContact(newData);
    }

    function ShowFilePage(){
        switch (page) {
            case "home":
                return(
                    <>                    
                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Dados do site</div>
                            </div>
                            <div className="list_input_data">                        
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setTitle_1(e.target.value) } } value={ title_1 } maxLength="140" required />
                                    <span className="name_input">Título</span>
                                </div>
                                <span className="div_input div_add_img">
                                    <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                        <label>
                                            { status == true ? "Foto adicionada" : "Adicionar foto" }
                                            <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]) } } accept="image/*" />
                                        </label>
                                    </div>
                                    {
                                        file !='' ?
                                        <>                                        
                                            <div className="delete_img_add" onClick={ ()=>{ AddFile('') } }>
                                                <SvgDelete className="icons" color="#ffffff" />
                                            </div>
                                            {
                                                status == true ?
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div> :
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + file) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div>
                                            }
                                        </>
                                        : null
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="show_page_data">
                            <div className="div_input space_div">
                                <img alt="home" src="./assets/example/01.jpg" className="show_img" />
                            </div>
                        </div>
                    </>
                )

            case "about":
                return(
                    <>                    
                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Dados do site</div>
                            </div>
                            <div className="list_input_data">                        
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setTitle_1(e.target.value) } } value={ title_1 } maxLength="140" required />
                                    <span className="name_input">Título</span>
                                </div>
                                <span className="div_input div_add_img">
                                    <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                        <label>
                                            { status == true ? "Foto adicionada" : "Adicionar foto" }
                                            <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]) } } accept="image/*" />
                                        </label>
                                    </div>
                                    {
                                        file !='' ?
                                        <>                                        
                                            <div className="delete_img_add" onClick={ ()=>{ AddFile('') } }>
                                                <SvgDelete className="icons" color="#ffffff" />
                                            </div>
                                            {
                                                status == true ?
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div> :
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + file) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div>
                                            }
                                        </>
                                        : null
                                    }
                                </span>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Texto 1</div>
                                    <JoditEditor className="show_textarea" config={ config } value={ text_1 ? text_1.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText_1(newContent) } />
                                </div>
                            </div>

                            <div className="list_input_data">                        
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setSubtitle_1(e.target.value) } } value={ subtitle_1 } maxLength="140" required />
                                    <span className="name_input">Subtitulo 1</span>
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Texto 2</div>
                                    <JoditEditor className="show_textarea" config={ config } value={ text_2 ? text_2.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText_2(newContent) } />
                                </div>
                            </div>
                        </div>
                        <div className="show_page_data">
                            <div className="div_input space_div">
                                <img alt="home" src="./assets/example/02.jpg" className="show_img" />
                            </div>
                        </div>
                    </>
                )

            case "roof":
                return(
                    <>                    
                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Dados do site</div>
                            </div>
                            <div className="list_input_data">                        
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setTitle_1(e.target.value) } } value={ title_1 } maxLength="140" required />
                                    <span className="name_input">Título</span>
                                </div>
                                <span className="div_input div_add_img">
                                    <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                        <label>
                                            { status == true ? "Foto adicionada" : "Adicionar foto" }
                                            <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]) } } accept="image/*" />
                                        </label>
                                    </div>
                                    {
                                        file !='' ?
                                        <>                                        
                                            <div className="delete_img_add" onClick={ ()=>{ AddFile('') } }>
                                                <SvgDelete className="icons" color="#ffffff" />
                                            </div>
                                            {
                                                status == true ?
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div> :
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + file) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div>
                                            }
                                        </>
                                        : null
                                    }
                                </span>
                            </div>

                            <div className="list_input_data">                        
                                <div className="div_input">
                                    <input type="text" className="btn_numb" onChange={ (e)=>{ setBtn_1(e.target.value) } } value={ btn_1 } maxLength="16" required />
                                    <span className="name_input">Número 1</span>
                                </div>                    
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setName_1(e.target.value) } } value={ name_1 } maxLength="140" required />
                                    <span className="name_input">Nome 1</span>
                                </div>
                            </div>

                            <div className="list_input_data">                        
                                <div className="div_input">
                                    <input type="text" className="btn_numb" onChange={ (e)=>{ setBtn_2(e.target.value) } } value={ btn_2 } maxLength="140" required />
                                    <span className="name_input">Número 2</span>
                                </div>                    
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setName_2(e.target.value) } } value={ name_2 } maxLength="140" required />
                                    <span className="name_input">Nome 2</span>
                                </div>
                            </div>

                            <div className="list_input_data">                        
                                <div className="div_input">
                                    <input type="text" className="btn_numb" onChange={ (e)=>{ setBtn_3(e.target.value) } } value={ btn_3 } maxLength="140" required />
                                    <span className="name_input">Número 3</span>
                                </div>                    
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setName_3(e.target.value) } } value={ name_3 } maxLength="140" required />
                                    <span className="name_input">Nome 3</span>
                                </div>
                            </div>

                            <div className="list_input_data">                        
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setSubtitle_1(e.target.value) } } value={ subtitle_1 } maxLength="140" required />
                                    <span className="name_input">Subtitle 1</span>
                                </div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Texto 1</div>
                                    <JoditEditor className="show_textarea" config={ config } value={ text_1 ? text_1.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText_1(newContent) } />
                                </div>
                            </div>

                            <div className="list_input_data">                        
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setSubtitle_2(e.target.value) } } value={ subtitle_2 } maxLength="140" required />
                                    <span className="name_input">Subtitle 2</span>
                                </div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Texto 2</div>
                                    <JoditEditor className="show_textarea" config={ config } value={ text_2 ? text_2.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText_2(newContent) } />
                                </div>
                            </div>

                            <div className="list_input_data">                        
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setTitle_2(e.target.value) } } value={ title_2 } maxLength="140" required />
                                    <span className="name_input">Título 2</span>
                                </div>
                            </div>

                            <div className="list_input_data">                        
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setBtn_4(e.target.value) } } value={ btn_4 } maxLength="140" required />
                                    <span className="name_input">Nome 4</span>
                                </div>                    
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setName_4(e.target.value) } } value={ name_4 } maxLength="140" required />
                                    <span className="name_input">Subtitulo do nome 4</span>
                                </div>
                            </div>

                            <div className="list_input_data">                        
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setBtn_5(e.target.value) } } value={ btn_5 } maxLength="140" required />
                                    <span className="name_input">Nome 5</span>
                                </div>                    
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setName_5(e.target.value) } } value={ name_5 } maxLength="140" required />
                                    <span className="name_input">Subtitulo do nome 5</span>
                                </div>
                            </div>

                            <div className="list_input_data">                        
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setBtn_6(e.target.value) } } value={ btn_6 } maxLength="140" required />
                                    <span className="name_input">Nome 6</span>
                                </div>                    
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setName_6(e.target.value) } } value={ name_6 } maxLength="140" required />
                                    <span className="name_input">Subtitulo do nome 6</span>
                                </div>
                            </div>

                            <div className="list_input_data">                        
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setSubtitle_3(e.target.value) } } value={ subtitle_3 } maxLength="140" required />
                                    <span className="name_input">Subtitle 3</span>
                                </div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Texto 3</div>
                                    <JoditEditor className="show_textarea" config={ config } value={ text_3 ? text_3.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText_3(newContent) } />
                                </div>
                            </div>
                        </div>
                        <div className="show_page_data">
                            <div className="div_input space_div">
                                <img alt="home" src="./assets/example/03.jpg" className="show_img" />
                            </div>
                        </div>
                    </>
                )

            case "contact":
                return(
                    <>                    
                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Dados do site</div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Texto 1</div>
                                    <JoditEditor className="show_textarea" config={ config } value={ text_1 ? text_1.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText_1(newContent) } />
                                </div>
                            </div>
                            <div className="list_input_data">                        
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setTitle_1(e.target.value) } } value={ title_1 } maxLength="140" required />
                                    <span className="name_input">Título 1</span>
                                </div>
                            </div>
                            <div className="list_input_data">                        
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ setTitle_2(e.target.value) } } value={ title_2 } maxLength="140" required />
                                    <span className="name_input">Título 2</span>
                                </div>
                            </div>

                            <div className="list_input_data">  
                                {
                                    listContact.map((key, index)=>{
                                        return(
                                            <div className="div_input space_div show_data_text" key={ index }>
                                                <div className="list_input_data">
                                                    <div className="div_input space_div">
                                                        <input type="text" className="data_input" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ key.title } maxLength="140" required />
                                                        <span className="name_input">Título btn { index + 1 }</span>
                                                    </div>
                                                    <div className="div_input">
                                                        <input type="text" className="data_input" onChange={ (e)=>{ HandleData('phone', index, e.target.value) } } value={ key.phone } maxLength="30" required />
                                                        <span className="name_input">Contato { index + 1 }</span>
                                                    </div>
                                                    <div className="div_input">
                                                        <input type="text" className="data_input" onChange={ (e)=>{ HandleData('whatsapp', index, e.target.value) } } value={ key.whatsapp } maxLength="30" />
                                                        <span className="name_input">Whatsapp { index + 1 }</span>
                                                    </div>
                                                    <div className="div_input space_div">
                                                        <input type="text" className="data_input" onChange={ (e)=>{ HandleData('email', index, e.target.value) } } value={ key.email } maxLength="40" required />
                                                        <span className="name_input">Email { index + 1 }</span>
                                                    </div>
                                                </div>
                                                <div className="list_input_data">
                                                    <div className="div_input space_div">
                                                        <input type="text" className="data_input" onChange={ (e)=>{ HandleData('address', index, e.target.value) } } value={ key.address } maxLength="140" required />
                                                        <span className="name_input">Endereço { index + 1 }</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div> 
                        </div>
                        <div className="show_page_data">
                            <div className="div_input space_div">
                                <img alt="home" src="./assets/example/04.jpg" className="show_img" />
                            </div>
                        </div>
                    </>
                )
        }
    }

    function ReturnPage(){
        SetListPag('currentPage', 'site');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Site(idPage, title_1, title_2, file, subtitle_1, text_1, subtitle_2, text_2, subtitle_3, text_3, btn_1, name_1, btn_2, name_2, btn_3, name_3, btn_4, name_4, btn_5, name_5, btn_6, name_6, listContact, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "site", "type" : type, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Page_AccessDash_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <div className="newtons-cradle">
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                </div>
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes - { namePage }</div>
                    <button className="new_block_text" onClick={ ()=>{  } }>
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                { ShowFilePage() }

            </form>
        </div>
    )
}