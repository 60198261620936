import { useState, useEffect } from "react";

import './Exit.css';

import { SetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

import { SvgAddNewData, SvgChekedReturn, SvgClose, SvgDelete, SvgEdit, SvgImg, SvgPdf, SvgSearch, SvgTriagle } from "components/SvgFile";

import { monthList } from "dataFixed";

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

export default function Page_Finacial_Exit(){

    const date = new Date();

    const [ search, setSearch ]     = useState('');
    const [ pageData, setPageData ] = useState(GetDataPage('exit'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(pageData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = pageData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('exit', setPageData);
    }, []);

    function SearchInput(value){
        const newList  = [];
        if(value){
            GetDataPage('exit').map((key, index)=>{
                if(key.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(key);
                }
                if(key.name_bank.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(key);
                }
                if(key.name_origin.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(key);
                }
                if(key.name_payment.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(key);
                }
                if(key.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(key);
                }
            })
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setPageData(duplicate);
        }else {
            setPageData(GetDataPage('exit'));
        }
        setSearch(value);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'exit_details')
        SetListPag('currentPageId', idPage);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "exit", "type" : "delete_exit", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Finacial_Entry">
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#111827" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="pesquisar..." />
                </div>
                <div className="title_page">Faturamento</div>
                <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                    <SvgAddNewData color="#ffffff" className="icons" />
                    faturamento
                </div>
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th width="200">Destino</th>
                            <th>Título</th>
                            <th width="60">Valor</th>
                            <th width="60" align="center">Data pagamento</th>
                            <th width="90" align="right">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{
                                let ext = '';
                                if(key.file !=''){
                                    let show_ext = key.file.split('.');
                                    ext = show_ext[1];
                                }

                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.name_origin }</td>
                                        <td>{ key.title }</td>
                                        <td>{ key.cash }</td>
                                        <td>{ key.payment_data_br }</td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                {
                                                    key.status == 1 ? 
                                                    <div data-tooltip-id={ "pay_" + key.id } data-tooltip-content="Pagamento realizado" data-tooltip-place="top">
                                                        <SvgChekedReturn className="icons" color="#5D9200" />
                                                    </div> :    
                                                        key.status == 2 ?                                                  
                                                        <div data-tooltip-id={ "pay_" + key.id } data-tooltip-content="Pagamento cancelado" data-tooltip-place="top">
                                                            <SvgClose className="icons" color="#f00000" />
                                                        </div> : null
                                                }
                                                <Tooltip id={ "pay_" + key.id } />
                                                {
                                                    key.file !='' ?
                                                        <>
                                                            {
                                                                ext == "pdf" ?
                                                                <>
                                                                    <div data-tooltip-id={ "file_" + key.id } data-tooltip-content="Visualizar comprovante" data-tooltip-place="top" onClick={ ()=>{ OpenFile('showPdf', 'financial_exit/' + key.file) } }>
                                                                        <SvgPdf color="#324d6b" className="icons" />
                                                                    </div>
                                                                    <Tooltip id={ "file_" + key.id } />
                                                                </> :
                                                                <>
                                                                    <div data-tooltip-id={ "file_" + key.id } data-tooltip-content="Visualizar comprovante" data-tooltip-place="top" onClick={ ()=>{ OpenFile('showImg', 'financial_exit/' + key.file) } }>
                                                                        <SvgImg color="#324d6b" className="icons" />
                                                                    </div>
                                                                    <Tooltip id={ "file_" + key.id } />
                                                                </>
                                                            }
                                                        </>
                                                    : ""
                                                }
                                                <div data-tooltip-id={ "del_" + key.id } data-tooltip-content="Deletar comprovante" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, key.title) } }>
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <Tooltip id={ "del_" + key.id } />
                                                <div data-tooltip-id={ "edi_" + key.id } data-tooltip-content="Editar comprovante" data-tooltip-place="top" onClick={ ()=>{ PageClick(key.id) } }>
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                                <Tooltip id={ "edi_" + key.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            {
                pageData.length >= 50 ?
                <div className="list_pages">
                    {
                        Array.from(Array(page), (item, index)=>{
                            return(
                                <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                    { index + 1 }
                                </div>
                            )
                        })
                    }
                </div> : null
            }
        </div>
    )
}