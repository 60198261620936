import { useState, useEffect } from "react";

import './Site.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { SvgAddNewData, SvgDelete, SvgEdit, SvgSave, SvgSetaDown, SvgSetaUp } from "components/SvgFile";

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

export default function Page_Site(){

    const [ loading, setLoading ]   = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('site'));  

    useEffect(()=>{
        RegisterDataPage('site', setDataPage);
    }, []); 

    function PageClick(idPage){
        SetListPag('currentPage', 'site_details')
        SetListPag('currentPageId', idPage);
    } 

    function ChangePosition(id, order, type){
        setLoading(true);
        // Reg_AltPosition('site', id, order, type, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "site", "type" : "delete_block", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Page_Site">
            <div className="new_block">
                <div className="title_page">Site</div>
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th width="120">Página</th>
                            <th>Nome</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            dataPage.map((key, index)=>{                                
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ index + 1 }</td>
                                        <td>{ key.name }</td>
                                        <td>{ key.title_1 }</td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div data-tooltip-id={ "del_" + key.id } data-tooltip-content="Deletar usuário" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, key.name) } } title="Deletar usuário">
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <Tooltip id={ "del_" + key.id } />

                                                <div data-tooltip-id={ "edi_" + key.id } data-tooltip-content="Editar usuário" data-tooltip-place="top" onClick={ ()=>{ PageClick(key.id) } } title="Editar usuário">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                                <Tooltip id={ "edi_" + key.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}