import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_Products } from "services/RegisterData";

import { SvgDelete, SvgImg, SvgReturn, SvgSave } from "components/SvgFile";

export default function Page_Products_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('products'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ title, setTitle ]   = useState(InitialData('title'));
    const [ text_1, setText_1 ] = useState(InitialData('text_1'));
    const [ text_2, setText_2 ] = useState(InitialData('text_2'));
    const [ file, setFile ]     = useState(InitialData('file'));
    const [ status, setStatus ] = useState(false);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('products', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('products'));
        setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title'));
        setText_1(InitialData('text_1'));
        setText_2(InitialData('text_2'));
        setFile(InitialData('file'));
        setStatus(false);
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        return '';
    }

    function ReturnPage(){
        SetListPag('currentPage', 'products');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Products(idPage, title, text_1, text_2, file, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "products", "type" : type, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Page_Products_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <div className="newtons-cradle">
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                </div>
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text" onClick={ ()=>{  } }>
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do produto</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="data_input" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                            <span className="name_input">Título</span>
                        </div>                        
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]) } } accept="image/*" />
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>                                        
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('') } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        status == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "products/" + file) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Texto 1</div>
                            <JoditEditor className="show_textarea" config={ config } value={ text_1 ? text_1.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText_1(newContent) } />
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Texto 2</div>
                            <JoditEditor className="show_textarea" config={ config } value={ text_2 ? text_2.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText_2(newContent) } />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}