import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, SetListPag, RegisterListPag } from "interface/Page";

import { SvgDelete, SvgImg, SvgPdf, SvgReturn, SvgSave } from "components/SvgFile";

import { Reg_Financial } from "services/RegisterData";

import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

export default function Page_Finacial_Exit_details(){

    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('exit'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId')); 
    
    const [ listPayment, setListPayment ] = useState(GetDataPage('list_payment'));
    const [ listBank, setListBank ]       = useState(GetDataPage('list_bank'));
    const [ listOrigin, setListOrigin ]   = useState(GetDataPage('list_origin'));   

    const [ title, setTitle ]                   = useState(InitialData('title'));
    const [ status, setStatus ]                 = useState(InitialData('status'));
    const [ typePayment, setTypePayment ]       = useState(InitialData('type_payment'));
    const [ namePayment, setNamePayment ]       = useState(InitialData('name_payment'));
    const [ newTypePayment, setNewTypePayment ] = useState('');
    const [ bank, setBank ]                     = useState(InitialData('bank'));
    const [ nameBank, setNameBank ]             = useState(InitialData('name_bank'));
    const [ newBank, setNewBank ]               = useState('');
    const [ agency, setAgency ]                 = useState(InitialData('agency'));
    const [ bill, setBill ]                     = useState(InitialData('bill'));
    const [ dueDate, setDueDate ]               = useState(InitialData('due_date'));
    const [ paymentData, setPaymentData ]       = useState(InitialData('payment_data'));
    const [ cash, setCash ]                     = useState(InitialData('cash'));
    const [ fee, setFee ]                       = useState(InitialData('fee'));
    const [ file, setFile ]                     = useState(InitialData('file'));
    const [ upload, setUpload ]                 = useState(false);
    const [ origin, setOrigin ]                 = useState(InitialData('origin'));
    const [ nameOrigin, setNameOrigin ]         = useState(InitialData('name_origin'));
    const [ newOrigin, setNewOrigin ]           = useState('');
    const [ text, setText ]                     = useState(InitialData('text'));

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('exit', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('exit'));
        setIdPage(GetListPag('currentPageId'));

        setListPayment(GetDataPage('list_payment'));
        setListBank(GetDataPage('list_bank'));
        setListOrigin(GetDataPage('list_origin'));

        setTitle(InitialData('title'));
        setStatus(InitialData('status'));
        setTypePayment(InitialData('type_payment'));
        setNamePayment(InitialData('name_payment'));
        setNewTypePayment('');
        setBank(InitialData('bank'));
        setNameBank(InitialData('name_bank'));
        setNewBank('');
        setAgency(InitialData('agency'));
        setBill(InitialData('bill'));
        setDueDate(InitialData('due_date'));
        setPaymentData(InitialData('payment_data'));
        setCash(InitialData('cash'));
        setFee(InitialData('fee'));
        setFile(InitialData('file'));
        setUpload(false);
        setOrigin(InitialData('origin'));
        setNameOrigin(InitialData('name_origin'));
        setNewOrigin('');
        setText(InitialData('text'));
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'name_payment' || type == 'name_bank' || type == 'name_origin'){
            return "Selecione uma opção..."
        }
        return '';
    }

    function ShowTypePayment(){
        const options = [{ "value": "add", "label": "Adicionar novo" }]; 
        listPayment.map((key, index)=>{
            options.push({
                "value": key.id, "label": key.name
            });
        })
        return options;
    }

    function ShowBank(){ 
        const options = [{ "value": "add", "label": "Adicionar novo" }]; 
        listBank.map((key, index)=>{
            options.push({
                "value": key.id, "label": key.name
            });
        })
        return options;
    }

    function ShowOrigin(){   
        const options = [{ "value": "add", "label": "Adicionar novo" }]; 
        listOrigin.map((key, index)=>{
            options.push({
                "value": key.id, "label": key.name
            });
        })
        return options;
    }

    function AddFile(value){
        setFile(value);
        if(value){
            setUpload(true);
        }else {
            setUpload(false);
        }
    }

    function ShowFile(value){
        switch (upload) {
            case true:
                let typeFile = value.type.split('/');
                switch (typeFile[0]) {
                    case "image":
                        return (
                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(value)) } }>
                                <SvgImg color="#324d6b" className="icons"/>
                            </div>
                        )
                                
                    case "application":
                        return (
                            <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf_local', URL.createObjectURL(value)) } }>
                                <SvgPdf color="#324d6b" className="icons"/>
                            </div>
                        )
                }

            case false:
                let dataFile = InitialData('file').split('.');
                switch (dataFile[1]) {
                    case 'pdf':   
                        return (
                            <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf', "financial_exit/" + value) } }>
                                <SvgPdf color="#324d6b" className="icons"/>
                                </div>
                            )  

                    default:
                        return (
                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "financial_exit/" + value) } }>
                                <SvgImg color="#324d6b" className="icons"/>
                            </div>
                        )
                }
                break;
        }
    }

    function OpenFile(type, value){
        SetModalData('ShowFile', { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Financial('exit', idPage, userId, title, status, typePayment, newTypePayment, bank, newBank, agency, bill, dueDate, paymentData, cash, fee, file, origin, newOrigin, text, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setUpload(false);
        setLoading(false);        
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'exit');
        SetListPag('currentPageId', 'remuve');
    }

    return(
        <div className="FinancialExit_details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <div className="newtons-cradle">
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                    <div className="newtons-cradle__dot" />
                </div>
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text" onClick={ ()=>{  } }>
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do faturamento de saída</div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="input_name" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                            <span className="name_input">Título</span>
                        </div>
                        <div className="div_input">
                            <select onChange={ (e)=>{ setStatus(e.target.value) } } value={ status }>
                                <option value="0">Em aberto</option>
                                <option value="1">Pago</option>
                                <option value="2">Cancelado</option>
                            </select>
                            <span className="name_input">Status</span>
                        </div>
                    </div>
                    
                    <div className="list_input_data" style={ { marginTop: "16px" } }>
                        <div className="div_input space_div">
                            <Select className="select_search" defaultOptions value={ { "value": typePayment, "label": namePayment } } options={ ShowTypePayment() } onChange={ (e)=> { setTypePayment(e.value); setNamePayment(e.label); } } />
                            <span className="name_input">Tipo pagamento</span>
                        </div>

                        {
                            typePayment == 'add' ?
                            <div className="div_input">
                                <input type="text" className="input_data" onChange={ (e)=>{ setNewTypePayment(e.target.value) } } value={ newTypePayment } maxLength="60" />
                                <span className="name_input">Nome</span>
                            </div>
                            : null
                        }

                        <div className="div_input space_div">
                            <Select className="select_search" defaultOptions value={ { "value": bank, "label": nameBank } } options={ ShowBank() } onChange={ (e)=> { setBank(e.value); setNameBank(e.label); } } />
                            <span className="name_input">Banco</span>
                        </div>

                        {
                            bank == 'add' ?
                            <div className="div_input">
                                <input type="text" className="input_data" onChange={ (e)=>{ setNewBank(e.target.value) } } value={ newBank } maxLength="60" />
                                <span className="name_input">Nome</span>
                            </div>
                            : null
                        }

                        <div className="div_input">
                            <input type="text" className="input_data" onChange={ (e)=>{ setAgency(e.target.value) } } value={ agency } maxLength="20" />
                            <span className="name_input">Agência</span>
                        </div>

                        <div className="div_input">
                            <input type="text" className="input_agency" onChange={ (e)=>{ setBill(e.target.value) } } value={ bill } maxLength="20" />
                            <span className="name_input">Conta</span>
                        </div>
                    </div>

                    <div className="list_input_data" style={ { marginTop: "16px" } }>
                        <div className="div_input">
                            <input type="date" className="input_date" onChange={ (e)=>{ setDueDate(e.target.value) } } value={ dueDate } />
                            <span className="name_input">Data do vencimento</span>
                        </div>

                        <div className="div_input">
                            <input type="date" className="input_date" onChange={ (e)=>{ setPaymentData(e.target.value) } } value={ paymentData } />
                            <span className="name_input">Data do pagamento</span>
                        </div>

                        <div className="div_input">
                            <input type="text" className="input_cash" onChange={ (e)=>{ setCash(e.target.value) } } placeholder="0,00" value={ cash.replaceAll(' ', '') } maxLength="16" required />
                            <span className="name_input">Valor</span>
                        </div>

                        <div className="div_input">
                            <input type="text" className="input_fee" onChange={ (e)=>{ setFee(e.target.value) } } placeholder="0,00" value={ fee.replaceAll(' ', '') } maxLength="16" required />
                            <span className="name_input">Taxa</span>
                        </div>

                        <span className="div_input div_add_img">
                            <div className={ upload == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { upload == true ? "Comprovante adicionado" : "Adicionar comprovante" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]) } } accept="image/*" />
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>                                        
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('') } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        upload == true ? ShowFile(file) : ShowFile(file)
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>

                    <div className="list_input_data" style={ { marginTop: "16px" } }>
                        <div className="div_input space_div">
                            <Select className="select_search" defaultOptions value={ { "value": origin, "label": nameOrigin } } options={ ShowOrigin() } onChange={ (e)=> { setOrigin(e.value); setNameOrigin(e.label); } } />
                            <span className="name_input">Destino do pagamento</span>
                        </div>
                    </div>

                    {
                        origin == 'add' ?
                        <div className="list_input_data" style={ { marginTop: "16px" } }>
                            <div className="div_input space_div">
                                <input type="text" className="" onChange={ (e)=>{ setNewOrigin(e.target.value) } } value={ newOrigin } maxLength="140" />
                                <span className="name_input">Nome</span>
                            </div>
                        </div> : null
                    }

                    <div className="list_input_data" style={ { marginTop: "16px" } }>
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Observação</div>
                            <JoditEditor className="show_textarea" config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div>
                </div>
            </form>
            <PopUP_ReturnResponse />
        </div>
    )
}